import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { graphql } from "gatsby";

import Layout from "../components/layout";

const Contact = ({ data }) => {
  return (
    <Layout title={`Contact`}>
      <Container>
        <Row className="justify-content-center" noGutters>
          <Col className="mt-5 text-center" xs="12">
            <h1>{data.markdownRemark.frontmatter.title}</h1>
          </Col>
          <Col className="mt-3 text-center" xs="12">
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            ></div>
          </Col>
          <Col className="mb-5" xs="12" sm="8">
            <Form data-netlify="true" method="POST" name="contact">
              <Form.Group controlId="emailInput">
                <Form.Label>Email address:</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" />
              </Form.Group>
              <Form.Group controlId="textareaInput">
                <Form.Label>Message:</Form.Label>
                <Form.Control as="textarea" rows="5" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { title: { eq: "Contact" } }) {
      html
      frontmatter {
        description
        title
      }
    }
  }
`;
